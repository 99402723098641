import { VoiceClient } from "./client.gen";

export class LootBox {
    private lootBox: HTMLElement;
    private spinner: HTMLElement;
    private itemsContainer: HTMLElement;
    private resultDiv: HTMLElement;
    private modal: HTMLElement;
    private closeButton: HTMLElement;
    private client: VoiceClient;

    constructor(client: VoiceClient) {
        this.lootBox = document.getElementById('lootBox')!;
        this.spinner = document.getElementById('reward-spinner')!;
        this.itemsContainer = document.getElementById('items')!;
        this.resultDiv = document.getElementById('result')!;
        this.modal = document.getElementById('reward-spinner-modal')!;
        this.closeButton = document.getElementById('close-reward-modal')!;
        this.client = client;

        this.lootBox.addEventListener('click', () => this.openLootBox());
        this.closeButton.addEventListener('click', () => this.closeModal());
    }

    private async getReward() {
        const response = await this.client.getReward({
            eventId: 1
        });

        if (response.reward !== undefined) {
            return {
                reward: {
                    title: response.reward.title,
                    content: !response.reward.title.toLowerCase().includes("dolus")
                        ? `Here is your Steam key: ${response.reward.content}`
                        : `Here is your Dolus discount: ${response.reward.content}
           
           <h3>Dolus Installation Instructions:</h3>
           <ol>
             <li>Create an account on <a href="https://my.dolus.app/" target="_blank">https://my.dolus.app/</a></li>
             <li>Sign in</li>
             <li>Click the "Buy a license" button</li>
             <li>Apply the code during checkout</li>
           </ol>`,
                    icon: response.reward.icon,
                    backgroundColor: response.reward.backgroundColor
                },
                fillerItems: response.filler?.map((item: any) => ({
                    title: item.title,
                    icon: item.icon,
                    backgroundColor: item.backgroundColor
                }))
            };
        } else {
            this.closeModal();
        }
    }

    private createItems(serverResponse: any) {
        const { reward, fillerItems } = serverResponse;
        const allItems = [...fillerItems, reward, ...fillerItems.slice(0, 24)];
        const tripleItems = [...allItems, ...allItems, ...allItems];

        this.itemsContainer.innerHTML = tripleItems.map(item => `
            <div class="item" style="background-color: ${item.backgroundColor}">
                <div style="font-size: 20px;">${item.icon}</div>
                <div>${item.title}</div>
            </div>
        `).join('');
    }

    private spin(serverResponse: any) {
        this.createItems(serverResponse);

        const itemWidth = 60;
        const totalItems = this.itemsContainer.children.length;
        const rewardIndex = Math.floor(totalItems / 2);
        const targetPosition = -(rewardIndex * itemWidth) + (this.spinner.offsetWidth / 2) - (itemWidth / 2);

        this.itemsContainer.style.transition = 'none';
        this.itemsContainer.style.left = '0px';
        void this.itemsContainer.offsetWidth;
        this.itemsContainer.style.transition = 'left 5s cubic-bezier(0.25, 0.1, 0.25, 1)';
        this.itemsContainer.style.left = `${targetPosition}px`;

        setTimeout(() => {
            this.resultDiv.innerHTML = `
            <div class="reward-icon">${serverResponse.reward.icon}</div>
            <div class="reward-title">${serverResponse.reward.title}</div>
            <div class="reward-content">${serverResponse.reward.content}</div>
        `;
            this.resultDiv.style.display = 'block';
            requestAnimationFrame(() => {
                this.resultDiv.classList.add('show'); // Trigger the animation
            });
        }, 5000);
    }





    private openLootBox() {
        if (this.lootBox.classList.contains('open')) return;

        this.lootBox.classList.remove('spinning');
        void this.lootBox.offsetWidth;
        this.lootBox.classList.add('spinning');

        setTimeout(async () => {
            this.lootBox.classList.remove('spinning');
            this.lootBox.classList.remove('closed');
            this.lootBox.classList.add('open');

            this.modal.style.display = 'flex'; // Show the modal
            this.spinner.style.display = 'block';

            const serverResponse = await this.getReward();
            if (serverResponse !== undefined) {
                this.spin(serverResponse);
            }

        }, 1000);
    }

    private closeModal() {
        this.modal.style.display = 'none'; // Hide the modal
        this.resultDiv.style.display = 'none'; // Reset the result display for the next round
        this.spinner.style.display = 'none'; // Reset spinner display
    }
}
